var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"text-left",class:[_vm.isValidForm ? 'is-valid-form' : 'is-invalid-form'],attrs:{"novalidate":""}},[(_vm.useOtherAddressLabel)?_c('b-form-group',{attrs:{"id":"groupUseOtherAddress"}},[_c('base-check-box',{attrs:{"name":_vm.name + '-useotheraddress',"label":_vm.useOtherAddressLabel},model:{value:(_vm.address.useotheraddress),callback:function ($$v) {_vm.$set(_vm.address, "useotheraddress", $$v)},expression:"address.useotheraddress"}})],1):_vm._e(),(!_vm.address.useotheraddress)?[_c('b-form-group',{attrs:{"id":"groupFirstName","label":_vm.$t('address.FirstName') + ' *',"label-for":_vm.name + '-firstname'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.name + '-firstname',"autocomplete":"firstname","type":"text","focus":true,"placeholder":_vm.$t('address.FirstName'),"validations":[
          {
            condition: _vm.errors.has(_vm.name + '-firstname'),
            text: _vm.errors.first(_vm.name + '-firstname')
          }
        ]},model:{value:(_vm.address.firstname),callback:function ($$v) {_vm.$set(_vm.address, "firstname", $$v)},expression:"address.firstname"}})],1),_c('b-form-group',{attrs:{"id":"groupLastName","label":_vm.$t('address.LastName') + ' *',"label-for":_vm.name + '-lastname'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.name + '-lastname',"autocomplete":"lastname","type":"text","placeholder":_vm.$t('address.LastName'),"validations":[
          {
            condition: _vm.errors.has(_vm.name + '-lastname'),
            text: _vm.errors.first(_vm.name + '-lastname')
          }
        ]},model:{value:(_vm.address.lastname),callback:function ($$v) {_vm.$set(_vm.address, "lastname", $$v)},expression:"address.lastname"}})],1),_c('b-form-group',{attrs:{"id":"groupPhoneNumber","label":_vm.$t('address.PhoneNumber') + ' *',"label-for":_vm.name + '-telephone'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|phoneNumber'),expression:"'required|phoneNumber'"}],attrs:{"name":_vm.name + '-telephone',"autocomplete":"telephone","type":"tel","placeholder":_vm.$t('address.PhoneNumber'),"validations":[
          {
            condition: _vm.errors.has(_vm.name + '-telephone'),
            text: _vm.errors.first(_vm.name + '-telephone')
          }
        ]},model:{value:(_vm.address.telephone),callback:function ($$v) {_vm.$set(_vm.address, "telephone", $$v)},expression:"address.telephone"}})],1),_c('address-autocomplete',{ref:"addressAutocomplete",attrs:{"name":_vm.name,"address":_vm.address},on:{"changeCountry":function($event){return _vm.$emit('changeCountry', $event)}}})]:_vm._e(),(_vm.isBilling)?[_c('b-form-group',{attrs:{"id":"groupRequireInvoice","label-for":_vm.name + '-requireInvoice'}},[_c('base-check-box',{attrs:{"name":_vm.name + '-requireInvoice',"label":_vm.$t('address.RequireInvoice')},model:{value:(_vm.requireInvoice),callback:function ($$v) {_vm.requireInvoice=$$v},expression:"requireInvoice"}})],1),(_vm.requireInvoice)?[_c('b-form-group',{attrs:{"id":"groupCompany","label":_vm.$t('address.Company'),"label-for":_vm.name + '-company'}},[_c('base-input',{attrs:{"name":_vm.name + '-company',"autocomplete":"company","type":"text","placeholder":_vm.$t('address.Company')},model:{value:(_vm.address.company),callback:function ($$v) {_vm.$set(_vm.address, "company", $$v)},expression:"address.company"}})],1),_c('b-form-group',{attrs:{"id":"groupTaxVat","label":_vm.$t('address.TaxId_VatId') + ' *',"label-for":_vm.name + '-taxid'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.name + '-taxid',"autocomplete":"taxid","type":"text","placeholder":_vm.$t('address.TaxId_VatId'),"validations":[
            {
              condition: _vm.errors.has(_vm.name + '-taxid'),
              text: _vm.errors.first(_vm.name + '-taxid')
            }
          ]},model:{value:(_vm.address.vat_id),callback:function ($$v) {_vm.$set(_vm.address, "vat_id", $$v)},expression:"address.vat_id"}})],1)]:_vm._e()]:_vm._e(),(_vm.showSubmitButton)?_c('b-button',{attrs:{"type":"button","variant":"primary","disabled":!_vm.isValidForm,"block":true},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.$t('Continue')))]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }